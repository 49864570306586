// eslint-disable-next-line @typescript-eslint/no-empty-interface, semi
export default interface Offer extends OfferItem {}

export default class Offer implements OfferItem {
  constructor(offer: OfferItem) {
    Object.assign(this, offer);
  }

  get shortLocation () {
    return this.location!.parts.city;
  }

  get coords () {
    return {
      lat: parseFloat(this.location!.lat),
      lng: parseFloat(this.location!.lng)
    };
  }
}

